import { extendTailwindMerge } from 'tailwind-merge';

const isFontSize = (value: string) => {
  return /^\d+$/.test(value);
};

export const twMerge = extendTailwindMerge({
  override: {
    classGroups: {
      'font-size': [{ text: [isFontSize] }],
    },
  },
});
